import { Typography } from "@mui/material";

const CommentArea = (props) => {
	const storage = window.localStorage;
	const currUser = storage.getItem("SOCIALGRAM");

	const showUser = (user) => {
		if (user === currUser) return "YOU : ";
		return `${user} : `;
	};

	return (
		<Typography textAlign={"left"} marginTop={1}>
			<Typography component={"b"} variant="b">
				{showUser(props.userName)}
			</Typography>
			{props.comment}
		</Typography>
	);
};

export default CommentArea;
