import { Snackbar } from "@mui/material";
import { ThumbUp } from "@mui/icons-material";

const snackBarStyle = {
	position: "absolute",
	top: "40% !important",
	width: "100%",
	zIndex: 0,
	"> div": {
		backgroundColor: "transparent",
		minWidth: "auto",
		boxShadow: "none",
		display: "contents",
	},
};

const LikeAlert = (props) => {
	return (
		<Snackbar
			sx={snackBarStyle}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
			open={props.alert}
			autoHideDuration={1000}
			onClose={props.onClose}
			message={
				<ThumbUp
					color="primary"
					sx={{ fontSize: "60px", width: "auto" }}
				></ThumbUp>
			}
		/>
	);
};

export default LikeAlert;
