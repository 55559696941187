import {
	Box,
	Typography,
	Button,
	Modal,
	TextField,
	FormHelperText,
	IconButton,
	Snackbar,
	Alert,
} from "@mui/material";
import Image from "mui-image";
import { Camera, Send, Clear } from "@mui/icons-material";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { url } from "../helpers";

const useStyles = makeStyles((theme) => ({
	box: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		backgroundColor: "white",
		boxShadow: 24,
		padding: "20px",
		borderRadius: "4px",
		width: "100%",
		maxWidth: "450px",
		"@media (max-width: 455px)": {
			padding: "15px",
			maxWidth: "400px",
		},
		"@media (max-width: 405px)": {
			padding: "15px",
			maxWidth: "350px",
		},
		"@media (max-width: 355px)": {
			padding: "15px",
			maxWidth: "300px",
		},
	},
}));

export default function PostForm(props) {
	const classes = useStyles();
	const storage = window.localStorage;
	const currUser = () => storage.getItem("SOCIALGRAM");
	const [mediaSrc, setMediaSrc] = useState("");
	const [title, setPostTitle] = useState("");
	const [type, setPostType] = useState("text");
	const [username, setPostUsername] = useState(currUser());
	const [caption, setPostCaption] = useState("");
	const [alert, setAlert] = useState(false);
	const [alertType, setAlertType] = useState();
	const [alertMessage, setAlertMessage] = useState("");

	const previewFile = () => {
		const file = document.querySelector("input[type=file]").files[0];
		const fileType = file.type.split("/")[0];
		const reader = new FileReader();

		if (fileType !== "image") {
			setAlertMessage("Only image/gifs media type allowed");
			setAlertType("warning");
			setAlert(true);
			return;
		}

		reader.addEventListener(
			"load",
			function () {
				setPostType("image");
				setMediaSrc(reader.result);
			},
			false
		);

		if (file) {
			reader.readAsDataURL(file);
		}
	};

	const dateTime = () => {
		const d = new Date();
		const time = d.toLocaleTimeString("en-US", {
			hour: "2-digit",
			minute: "2-digit",
		});
		const date = d.toLocaleString("en-US", {
			month: "long",
			year: "numeric",
			day: "numeric",
		});
		return `${time} : ${date}`;
	};

	const post = async () => {
		if (!title || !username || !caption) {
			setAlertType("warning");
			setAlertMessage("Give all field(s)");
			setAlert(true);
			return;
		}

		const body = {
			title,
			username,
			content: { type, data: mediaSrc, caption },
			date: dateTime(),
			likes: 0,
			comments: 0,
		};
		const posting = await fetch(url("posts"), {
			method: "POST",
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		}).catch((error) => {
			console.log(error);
			setAlertMessage(error.message);
			setAlertType("error");
			setAlert(true);
		});

		if (posting) {
			const res = await posting.json();
			const status = await posting.status;

			setAlertMessage(res.message);
			if (status === 200) {
				setAlertType("success");
				await props.getPosts();
			} else {
				setAlertType("error");
			}
			setAlert(true);
		}

		return;
	};

	return (
		<Modal
			open={props.open}
			onClose={() => {
				props.closePostForm();
				setAlert(false);
			}}
		>
			<div>
				<Snackbar
					open={alert}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					autoHideDuration={1500}
					onClose={() => setAlert(false)}
				>
					{alertType && (
						<Alert
							variant="filled"
							onClose={() => setAlert(false)}
							severity={alertType}
							sx={{ width: "100%" }}
						>
							{alertMessage}
						</Alert>
					)}
				</Snackbar>
				<Box className={classes.box}>
					<Typography variant="h5">Post</Typography>
					<TextField
						label="Title"
						margin="dense"
						fullWidth
						onChange={(e) => setPostTitle(e.target.value)}
					></TextField>
					{/* <TextField
						label="Username"
						margin="dense"
						fullWidth
						onChange={(e) => setPostUsername(e.target.value)}
					></TextField> */}
					<TextField
						label="About"
						fullWidth
						margin="dense"
						multiline
						rows={3}
						onChange={(e) => setPostCaption(e.target.value)}
					></TextField>
					{mediaSrc !== "" && (
						<div>
							<IconButton
								aria-label="remove image"
								sx={{ position: "absolute", zIndex: 2 }}
								onClick={() => {
									setMediaSrc("");
									setPostType("text");
								}}
							>
								<Clear />
							</IconButton>
							<Image
								src={mediaSrc}
								height="300px"
								fit="contain"
								duration={1000}
							></Image>
						</div>
					)}
					<FormHelperText>(Only images allowed)</FormHelperText>
					<Button variant="outlined" component="label">
						<Camera sx={{ mr: 1 }}></Camera>
						Add Media
						<input type="file" hidden onChange={previewFile} />
					</Button>
					<Button
						variant="contained"
						style={{ marginTop: "12px" }}
						size="large"
						fullWidth
						onClick={post}
					>
						<Send sx={{ mr: 1 }}></Send>
						Post
					</Button>
				</Box>
			</div>
		</Modal>
	);
}
