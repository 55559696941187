import {
	CardContent,
	Collapse,
	TextField,
	Stack,
	IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import CommentArea from "./CommentArea";
import { url } from "../helpers";
import { Send } from "@mui/icons-material";

const Comments = (props) => {
	const storage = window.localStorage;
	const currUser = storage.getItem("SOCIALGRAM");
	const postId = props.postId;
	const [comments, setComments] = useState([]);
	const [comment, setComment] = useState("");

	const loadComments = async () => {
		if (!postId) return;

		let apiUrl = new URL(url("comments"));
		apiUrl.search = new URLSearchParams({ postId });
		const gettingComments = await fetch(apiUrl).catch((error) =>
			console.log(error.message)
		);

		if (gettingComments) {
			const status = gettingComments.status;
			if (status === 200) {
				const res = await gettingComments.json();
				setComments(JSON.parse(res));
				props.setComments(JSON.parse(res).length);
			}
		}
	};

	useEffect(() => {
		if (props.showComments === true) {
			loadComments();
		}
	}, []);

	const postComment = async () => {
		if (!comment) return;
		const body = { userName: currUser, comment, postId };
		const commenting = await fetch(url("comment"), {
			method: "POST",
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		}).catch((error) => console.log(error));

		if (commenting) {
			const res = await commenting.json();
			let updatedComments = [...comments];
			updatedComments.push(res);
			setComments(updatedComments);
			props.setComments(updatedComments.length);
		}
	};

	return (
		<Collapse in={props.showComments} timeout="auto" unmountOnExit>
			<CardContent
				sx={{ paddingTop: "0px", paddingBottom: "15px !important" }}
			>
				<Stack direction="row" spacing={1}>
					<TextField
						placeholder="Comment"
						size="small"
						fullWidth
						onChange={(e) => setComment(e.target.value)}
					></TextField>
					<IconButton color="primary" onClick={postComment}>
						<Send></Send>
					</IconButton>
				</Stack>
				<div style={{ paddingTop: "10px" }}>
					{comments.length === 0 && (
						<CommentArea
							userName={"("}
							comment="No Comments"
						></CommentArea>
					)}
					{comments.length > 0 &&
						comments.map((c, i) => (
							<div key={i}>
								<CommentArea
									userName={c.userName}
									comment={c.comment}
								></CommentArea>
							</div>
						))}
				</div>
			</CardContent>
		</Collapse>
	);
};

export default Comments;
