import { AppBar, Toolbar, Typography, Avatar, IconButton } from "@mui/material";
import { nameInitials } from "../helpers";

const NavBar = (props) => {
	return (
		<AppBar>
			<Toolbar>
				<Typography variant="h6" component="div">
					SocialGram
				</Typography>
				<IconButton sx={{ position: "absolute", right: "15px" }}>
					<Avatar sx={{ backgroundColor: "black" }} aria-label="user">
						{nameInitials(props.user)}
					</Avatar>
				</IconButton>
			</Toolbar>
		</AppBar>
	);
};

export default NavBar;
