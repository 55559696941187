import React, { useEffect, useState } from "react";
import PostCard from "./PostCard";
import PostForm from "./PostForm";
import NavBar from "./NavBar";
import Main from "./Main";
import { Box, Fab, Typography, CircularProgress } from "@mui/material";
import { Edit } from "@mui/icons-material";
import Image from "mui-image";
import { url } from "../helpers";

const boxStyle = {
	paddingTop: "75px",
	paddingBottom: "75px",
	display: "flex",
	justifyContent: "center",
	flexDirection: "column",
	textAlign: "center",
	alignItems: "center",
};

const Posts = () => {
	const storage = window.localStorage;
	const currUser = () => storage.getItem("SOCIALGRAM");
	const [user, setUser] = useState(currUser);
	const [main, setMain] = useState(true);
	const [loading, setLoading] = useState(true);
	const [posts, setPosts] = useState([]);
	const [postForm, setPostForm] = useState(false);

	const closePostForm = () => setPostForm(false);
	const getPosts = async () => {
		const res = await fetch(url("posts")).catch((error) =>
			console.log(error)
		);
		if (res) {
			const posts = await res.json();
			posts.sort((a, b) => b.postId - a.postId);
			setPosts(posts);
		}
		setLoading(false);
	};

	useEffect(() => {
		if (user) {
			setMain(true);
			getPosts();
		}
	}, []);

	return (
		<div style={{ width: "100%", height: "100%", margin: 0 }}>
			{!user && (
				<Main
					open={main}
					setMain={setMain}
					setUser={setUser}
					getPosts={getPosts}
				></Main>
			)}
			{user && (
				<div>
					<NavBar user={user}></NavBar>
					<Box sx={boxStyle}>
						{loading && (
							<CircularProgress
								sx={{ position: "fixed", top: "40%" }}
							/>
						)}
						{posts.map(
							(post) =>
								post.data.content && (
									<PostCard
										key={post.postId}
										unique={post.postId}
										title={post.data.title}
										username={post.data.username}
										date={post.data.date}
										content={post.data.content}
										likes={post.data.likes}
										comments={post.data.comments}
									></PostCard>
								)
						)}
						{posts.length === 0 && loading === false && (
							<div style={{ marginTop: "40px" }}>
								<Typography variant="h4" component="h1">
									No Posts
								</Typography>
								<Image
									src={
										"https://c.tenor.com/u6B7tMiwjdoAAAAi/emoji-expression.gif"
									}
									height="300px"
									fit="contain"
									duration={0}
								></Image>
							</div>
						)}
					</Box>
					<Fab
						variant="extended"
						color="primary"
						sx={{ position: "fixed", bottom: 20, right: 20 }}
						onClick={() => {
							setPostForm(true);
						}}
					>
						<Edit sx={{ mr: 1 }}></Edit>
						Post
					</Fab>
					<PostForm
						open={postForm}
						closePostForm={closePostForm}
						getPosts={getPosts}
					></PostForm>
				</div>
			)}
		</div>
	);
};

export default Posts;
