import {
	Card,
	CardHeader,
	CardMedia,
	CardContent,
	Typography,
	IconButton,
	CardActions,
	Avatar,
} from "@mui/material";
import Comments from "./Comments";
import { useState } from "react";
import { Favorite, Comment } from "@mui/icons-material";
import LikeAlert from "./LikeAlert";
import { nameInitials, url } from "../helpers";

const avatar = {
	backgroundColor: "var(--primary)",
};

const cardStyle = {
	maxWidth: 500,
	margin: "10px",
	width: "100%",
	position: "relative",
};

const PostCard = (props) => {
	const storage = window.localStorage;
	const currUser = storage.getItem("SOCIALGRAM");
	const [showComments, setShowComments] = useState(false);
	const [comments, setComments] = useState(props.comments);
	const [likes, setLikes] = useState(props.likes);
	const [alert, setAlert] = useState(false);

	const likePost = async (postId) => {
		if (props.username === currUser) return;
		if (!postId) return;

		const body = { postId, userName: currUser };
		const liking = await fetch(url("like"), {
			method: "POST",
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		}).catch((error) => {
			console.log(error.message);
		});

		if (liking) {
			const res = await liking.json();
			const status = liking.status;

			if (status === 200) {
				if (res.likes > likes) {
					setAlert(true);
				}
				setLikes(res.likes);
			}
		}
		return;
	};

	return (
		<Card sx={cardStyle}>
			<LikeAlert
				alert={alert}
				onClose={() => setAlert(false)}
			></LikeAlert>
			<CardHeader
				style={{ textAlign: "left" }}
				avatar={
					<Avatar aria-label="recipe" sx={avatar}>
						{nameInitials(props.username)}
					</Avatar>
				}
				title={props.title}
				subheader={props.date + " | By " + props.username}
			/>
			{props.content.type === "image" && (
				<CardMedia
					component="img"
					sx={{ maxHeight: "500px", objectFit: "contain" }}
					image={props.content.data}
					alt="Image"
				/>
			)}
			<CardContent>
				<Typography
					variant="body2"
					color="text.secondary"
					textAlign={"justify"}
				>
					{props.content.caption}
				</Typography>
			</CardContent>
			<CardActions disableSpacing>
				<IconButton
					aria-label="like"
					onClick={() => likePost(props.unique)}
				>
					<Favorite htmlColor="red" sx={{ mr: 0.5 }} />
					<Typography sx={{ fontSize: "18px" }}>{likes}</Typography>
				</IconButton>
				<IconButton
					aria-label="comment"
					onClick={() => setShowComments(!showComments)}
				>
					<Comment color="primary" sx={{ mr: 0.5 }} />
					<Typography sx={{ fontSize: "18px" }}>
						{comments}
					</Typography>
				</IconButton>
			</CardActions>
			{showComments && (
				<Comments
					showComments={showComments}
					postId={props.unique}
					setComments={setComments}
				></Comments>
			)}
		</Card>
	);
};

export default PostCard;
