import { Box, Typography, Button, Modal, TextField } from "@mui/material";
import { Send } from "@mui/icons-material";
import { useState } from "react";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	width: "100%",
	borderRadius: "4px",
	maxWidth: "400px",
	"@media (max-width: 480px)": {
		padding: "20px",
		maxWidth: "400px",
	},
	"@media (max-width: 400px)": {
		padding: "20px",
		maxWidth: "350px",
	},
	"@media (max-width: 300px)": {
		padding: "20px",
		maxWidth: "250px",
	},
};

export default function Main(props) {
	const [newUser, setNewUser] = useState("");
	const storage = window.localStorage;

	const createUser = () => {
		if (newUser) {
			storage.setItem("SOCIALGRAM", newUser);
			props.setMain(false);
			props.setUser(newUser);
			props.getPosts();
		}
	};

	return (
		<Modal open={props.open} onClose={props.setMain}>
			<Box sx={style}>
				<Typography textAlign={"center"} variant="h5">
					Welcome To SocialGram
				</Typography>
				<TextField
					label="Username"
					margin="dense"
					fullWidth
					onChange={(e) => setNewUser(e.target.value)}
				></TextField>
				<Button
					variant="contained"
					style={{ marginTop: "12px" }}
					size="large"
					fullWidth
					onClick={createUser}
				>
					<Send sx={{ mr: 1 }}></Send>
					Go
				</Button>
			</Box>
		</Modal>
	);
}
